<template>
    <v-container fluid style="padding-top: 70px; justify-content: center">
        <alert ref="alertComponent"></alert>
        <v-row align="center" justify="space-around">
            <v-btn tile outlined color="success" @click="loadRunners()">
                <v-icon left>mdi-cached</v-icon>
                Refresh
            </v-btn>
            <v-progress-linear v-if="loading" indeterminate color="cyan"></v-progress-linear>
        </v-row>

        <br/>

        <v-card>
            <!--search input-->
            <v-card-title>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                              hide-details></v-text-field>
            </v-card-title>

            <v-data-table :headers="headers" :items="runners" :search="search" disable-pagination hide-default-footer>
                <!-- add style to state -->
                <template v-slot:[`item.state`]="{ item }">
                    <v-btn :color="getColorForState(item)" class="mr-4">{{ item.state }}</v-btn>
                </template>

                <!-- update button -->
                <template v-slot:[`item.details`]="{ item }">
                    <v-btn v-if="item.state === 'DISABLED'" color="success" class="mr-4" @click="changeState(item)">Activate</v-btn>
                    <v-btn v-else color="error" class="mr-4" @click="changeState(item)">Disable</v-btn>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>

<script>
import Alert from "@/components/Alert.component";
import Services from "@/services/api";

export default {
    name: "Runners",
    components: {Alert},
    data: () => ({
        search: '',
        headers: [
            {text: 'Name', value: 'name', sortable: true},
            {text: 'State', value: 'state', sortable: true},
            {text: '', value: 'details', sortable: false},
        ],
        runners: [],
        loading: false
    }),
    computed: {
    },
    async mounted() {
        await this.loadRunners();
    },
    methods: {
        getColorForState(worker) {
            if(worker.state === 'WORKING' && worker.currentTask !== null) return 'blue darken-3';
            if(worker.state === 'DISABLED') return 'error';
            return 'warning';
        },
        getNewState(worker) {
            return worker.state === 'DISABLED' ? 'WAITING' : 'DISABLED';
        },
        loadRunners() {
            this.loading = true;
            Services.Pipelines.getAllRunners()
                .then(data => this.runners = [...data])
                .catch(e => this.$refs.alertComponent.showErrorMessage(e.message))
                .finally(() => this.loading = false)
        },
        changeState(worker) {
            this.loading = true;
            const state = this.getNewState(worker);
            Services.Pipelines.updateRunner({id: worker.id, state})
                .then(data => {
                  let index = this.runners.findIndex(r => r.id === worker.id);
                  this.runners[index] = data;
                  this.runners = [...this.runners];
                })
                .catch(e => this.$refs.alertComponent.showErrorMessage(e.message))
                .finally(() => this.loading = false)
        }
    }
}
</script>
